import { createSlice } from "@reduxjs/toolkit";
import { EditorState, convertToRaw } from "draft-js";

export const listingTemplate = {
  title: "",
  description: "",
  descriptionContent: JSON.stringify(
    convertToRaw(EditorState.createEmpty().getCurrentContent())
  ),
  location: {
    country: "",
    city: "",
    province: "",
  },
  price: 50000,
  annual_cashflow: null,
  size: null,
  real_estate: "Leased",
  lease_expiration: null,
  lease_information: "",
  // inventory: "Not included",
  pp_e: "Not included",
  employees: [],
  reasons_for_selling: "",
  turnover_support: "",
  attachments: {},
  images: [],
  status: "For Sale",
  tags: [],
  industry: { label: "N/A", value: "N/A" },
  lat: null,
  lng: null,
  // new
  cashflow: null,
  annual_sales: null,
  ebitda: null,
  fixed_assets: null,
  inventory: null,
  established: null,
};

const initialState = {
  listingCreate: listingTemplate,
  listingEdit: { ...listingTemplate, author_id: "" },
};

const currentListingSlice = createSlice({
  name: "currentListing",
  initialState,
  reducers: {
    setCreateListingDetail: (state, action) => {
      const { key, value } = action.payload;
      state.listingCreate[key] = value;
    },
    setEditListingDetail: (state, action) => {
      const { key, value } = action.payload;
      state.listingEdit[key] = value;
    },
    clearTemplate: (state, action) => {
      console.log(action.payload);
      switch (action.payload) {
        case "edit":
          state.listingEdit = initialState.listingEdit;
          break;
        case "create":
          state.listingCreate = initialState.listingCreate;
          break;
        default:
          return initialState;
      }
    },
    setCreateListing: (state, action) => {
      state.listingCreate = action.payload;
    },
    setEditListing: (state, action) => {
      state.listingEdit = action.payload;
    },
  },
});

export const {
  setCreateListingDetail,
  setCreateListing,
  setEditListingDetail,
  setEditListing,
  clearTemplate,
} = currentListingSlice.actions;
export default currentListingSlice.reducer;
