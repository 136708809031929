import { useState, useRef } from "react";
import { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useVerifyTokenQuery } from "../auth/authAPI";
import { useGetUserDetailsQuery } from "../user/userAPI";
import { useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { clearToken } from "../auth/authSlice";
import { useRouter } from "next/router";

const initialContextState = {
  user: null,
  setUser: () => {},
  authenticated: false,
  setAuthenticated: () => {},
  loading: true,
  setLoading: () => {},
};

// user context to store data instead
const UserContext = createContext(initialContextState);

export default UserContext;

export const UserContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(initialContextState.loading);
  const [authenticated, setAuthenticated] = useState(
    initialContextState.authenticated
  );
  const [user, setUser] = useState(initialContextState.user);

  useEffect(() => {
    const getUser = async () => {
      if (token) {
        const verifyTokenRes = await fetch(
          `${process.env.SLS_URL}/auth/verify-token`,
          {
            method: "GET",
            headers: { Authorization: token },
          }
        );

        const verifyTokenJson = await verifyTokenRes.json();

        if (verifyTokenJson && "status" in verifyTokenJson) {
          if (verifyTokenJson.status === 1) {
            setAuthenticated(true);

            const userRes = await fetch(`${process.env.SLS_URL}/user/details`, {
              method: "GET",
              headers: { Authorization: token },
            });

            const userResJson = await userRes.json();

            if (
              userResJson &&
              "status" in userResJson &&
              userResJson.status === 1
            ) {
              setUser(userResJson.user);
            }
          } else if (verifyTokenJson.status === 0) {
            setAuthenticated(false);
            dispatch(clearToken());
            router.push("/login");
          }
        }
        setLoading(false);
      }

      setLoading(false);
    };

    getUser();
  }, []);

  return (
    <UserContext.Provider value={{ authenticated, loading, user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
