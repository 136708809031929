import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.SLS_URL,
  }),

  endpoints: (builder) => ({
    getListings: builder.query({
      query: (date) => "/listing/all",
    }),
  }),
});

export const { useGetListingsQuery } = api;
