import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  keyword: "",
  city: "",
  industry: "",
  price: { min: 50000, max: 50000000 },
  page: 0,
  limit: 5,
  offset: 0,
  totalPages: 1,
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setIndustry: (state, action) => {
      state.industry = action.payload;
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    resetFilters: (state) => {
      state.keyword = "";
      state.city = "";
      state.industry = "";
      state.price = { min: 50000, max: 50000000 };
    },
    setPage: (state, action) => {
      state.page = parseInt(action.payload);
    },
    setLimit: (state, action) => {
      state.limit = parseInt(action.payload);
    },
    setOffset: (state, action) => {
      state.offset = parseInt(action.payload);
    },
    setTotalPages: (state, action) => {
      state.totalPages = parseInt(action.payload);
    },
  },
});

export const {
  setCity,
  setKeyword,
  setIndustry,
  setPrice,
  resetFilters,
  setPage,
  setLimit,
  setOffset,
  setTotalPages,
} = searchSlice.actions;

export default searchSlice.reducer;
