import UserContext from "../../features/user/UserContext";

import { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setCallbackUrl } from "../../features/auth/authSlice";
import { clearTemplate } from "../../features/listing/currentListingSlice";
import { setUserStateWithInitialState } from "../../features/user/userSlice";

const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserStateWithInitialState());
    dispatch(clearTemplate());
  }, []);

  useEffect(() => {
    const noRedirectUrls = ["/login", "/register"];
    const redirectUrl = window.location.pathname;

    if (!noRedirectUrls.includes(redirectUrl)) {
      dispatch(setCallbackUrl(redirectUrl));
    }
  }, [typeof window !== "undefined" && window.location.pathname]);

  return <></>;
};

export default Index;
