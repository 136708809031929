import Head from "next/head";

const Seo = ({ pageTitle, font }) => {
  return (
    <>
      <Head>
        <title>{pageTitle && `LiztaBiz | ${pageTitle} `}</title>
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="keywords"
          content="advanced custom search, agency, agent, business, clean, corporate, directory, google maps, homes, idx agent, listing properties, membership packages, business, real broker, real estate, real estate agent, real estate agency, realtor"
        />
        <meta
          name="description"
          content="LiztaBiz - Real Estate React Template"
        />
        <meta name="ibthemes" content="ATFN" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        {font && <link href={font} rel="stylesheet" />}
        <link rel="icon" href="/favicon.ico" />
        {/* <script src="https://cdn.tailwindcss.com"></script> */}
      </Head>
    </>
  );
};

export default Seo;
