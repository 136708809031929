import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "./storage";
import { persistReducer, persistStore } from "redux-persist";

import agentSlice from "../features/agent/agentSlice";
import { api } from "../features/api/api";
import filterSlice from "../features/filter/filterSlice";
import propertiesSlice from "../features/properties/propertiesSlice";
import searchSlice from "../features/search/searchSlice";
import userSlice from "../features/user/userSlice";
import authSlice from "../features/auth/authSlice";
import currentListingSlice from "../features/listing/currentListingSlice";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  properties: propertiesSlice,
  filter: filterSlice,
  agent: agentSlice,
  search: searchSlice,
  user: userSlice,
  auth: authSlice,
  currentListing: currentListingSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "search"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
