import { api } from "../api/api";

const authAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    verifyToken: builder.query({
      query: (token) => ({
        url: "/auth/verify-token",
        headers: { Authorization: token },
        method: "GET",
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        body: data,
      }),
    }),
    signup: builder.mutation({
      query: (data) => ({
        url: "/auth/sign-up",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useVerifyTokenQuery, useLoginMutation, useSignupMutation } =
  authAPI;
