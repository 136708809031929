import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fname: "",
  lname: "",
  _id: "",
  email: "",
  contact_no: "",
  address: "",
  profile_pic: "",
  facebook_id: "",
  google_id: "",
  socials: {
    fb: "",
    instagram: "",
    twitter: "",
    linkedin: "",
    youtube: "",
    website: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      const {
        fname,
        lname,
        _id,
        email,
        contact_no,
        address,
        profile_pic,
        google_id,
        facebook_id,
        socials,
      } = action.payload;

      state._id = _id;
      state.fname = fname;
      state.lname = lname;
      state.email = email;
      state.contact_no = contact_no;
      state.address = address;
      state.profile_pic = profile_pic;
      state.google_id = google_id;
      state.facebook_id = facebook_id;
      state.socials = socials;
    },
    clearUserDetails: (state, action) => {
      return initialState;
    },
    setProfilePic: (state, action) => {
      state.profile_pic = action.payload;
    },
    // return initial state with current state
    setUserStateWithInitialState: (state, action) => {
      return { ...initialState, ...state };
    },
  },
});

export const { setUserDetails, clearUserDetails, setProfilePic, setUserStateWithInitialState } =
  userSlice.actions;
export default userSlice.reducer;
