import { api } from "../api/api";

export const userAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: ({ token, dateFetched }) => ({
        url: "/user/details",
        headers: { Authorization: token },
        method: "GET",
      }),
    }),
  }),
});

export const { useGetUserDetailsQuery } = userAPI;
