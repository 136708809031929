import { Provider } from "react-redux";
import { UserContextProvider } from "../features/user/UserContext";
import { store } from "../app/store";

import ScrollToTop from "../components/common/ScrollTop";
import Seo from "../components/common/seo";
import URLSetter from "../components/common/URLSetter";
import { ToastContainer } from "react-toastify";
import "../index.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { GoogleOAuthProvider } from "@react-oauth/google";

if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}

function MyApp({ Component, pageProps }) {
  return (
    <>
      {/* <Seo
        font={
          "https://fonts.googleapis.com/css?family=Nunito:400,400i,500,600,700&display=swap"
        }
      /> */}
      <Seo
        font={
          "https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed:ital,wght@0,400;0,500;0,600;0,700;1,400;1,600&display=swap"
        }
      />
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
        <Provider store={store}>
          <UserContextProvider>
            <ToastContainer
              pauseOnHover={false}
              position="top-center"
              autoClose={3000}
              draggable={false}
            />
            <URLSetter />
            <Component {...pageProps} />
          </UserContextProvider>
        </Provider>
      </GoogleOAuthProvider>

      <ScrollToTop />
    </>
  );
}

export default MyApp;
